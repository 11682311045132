
.zx_com_navbar{
  background-color: rgba(53, 51, 83,0.75);
  font-size: medium;
}

.zx_com_navbar_icon{
  max-width: 2rem;
}

.zx_com_navbar_right_end{
  margin-right: 1rem;
}

.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

