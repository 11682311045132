
.zx_page_index_l5_intro {
    position: relative;
    z-index: 1;
    margin: auto;
    max-width: 540px;
    text-align: center;
    font-size: xx-large;
    color: lightgray;
}
