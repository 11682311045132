.bg-image {
    padding: 0;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
    background-image: url('/public/assets/background.svg');
    background-size: 100% auto;
    background-repeat: repeat;
    background-position: top center;
}
/*
@media only screen and (max-width:768px) {
    .bg-image {
        padding: 300px 0;
    }
}
*/