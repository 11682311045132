* {
  margin: 0;
  padding: 0;
}

html {
  overflow: auto;
  margin: 0;
}

body {
  padding-right: 0px !important;
}