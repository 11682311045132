
#notebook_input {
    width: 100%;
    min-height: 8em; /* Minimum height of 4 lines */
    max-height: 16em; /* Minimum height of 4 lines */
    height: auto;
    resize: vertical; /* Allow vertical resizing */
    background-color: transparent;
}

.zx_page_l2_main{
    flex-wrap: wrap;
}
