.za_navbar2_0 {
  background-color: rgba(189, 93, 56, 1);
  font-size: medium;

  top: 0;
  right: 0;
  left: 0;
}

.za_navbar2_0_right_end {
  margin-right: 1rem;
}

.za_navbar2_0_left_end {
  margin-left: 1rem;
}

.za_navbar3_pills_spacing {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.za_navbar2_img1_1 {
  max-height: 2rem;
}

.za_navbar1_img1_1 {
  max-height: 12rem;
}

.za_navbar1_0 {
  overflow-y: auto;
  /* Optional: to add a scrollbar if the content overflows */
  background-color: rgba(189, 93, 56, 1);

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 20% !important;
}

.za_sbase_navleft {
  margin-left: 20%;

  flex: 0 0 auto;
  width: 80% !important;
}

.za_s-7-3-btn {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  width: 100%;
}

.za_section {
  min-height: 100vh;
  max-width: 100vw !important;
  padding: 2rem !important;

  font-family: 'GoogleFonts-Inter-VariableFont_opsz_wght';
}

.za_navbar3_nav-item {
  font-weight: bold !important;
}

.za_navbar3_nav-item.active {
  background-color: transparent !important;
  font-weight: bold !important;
  color: aqua !important;

  border-style: groove;
  border-radius: 1rem !important;
  border-width: 0.3rem;
  border-color: rgb(248, 222, 172);
}

#s-2-main-header {
  padding-top: 1rem;
  padding-bottom: 0px;
}


#misc_modal_root {
  height: 0px;
  width: 0px;
}

.s-2-proj-prev-l0 {
  cursor: pointer;
  position: relative;
  display: block;
  border-radius: 2rem;
  overflow: hidden;
  box-sizing: border-box;
}

.s-2-proj-prev-lc0 {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background-color: rgba(82, 81, 116, 0.6);

  align-items: center !important;
  justify-content: center !important;

  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  box-sizing: border-box;

  z-index: 4;
}

.s-2-proj-prev-lc0:hover {
  opacity: 1;
}

.s-2-proj-prev-lp0 {
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  z-index: 2;
}

.s-2-proj-prev-lbsc0 {
  width: 100vw;
  height: 50vh;
}

.s-2-proj-prev-lw0 {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  box-sizing: border-box;

  color: rgb(233, 250, 254);
  text-shadow: 1px 1px rgb(179, 237, 250);
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 3;
  white-space: nowrap
}

.s-2-proj-prev-lw1_l1 {
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.s-2-proj-prev-lw1_l3 {
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.s-2-proj-prev-lw1_l6 {
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.s-2-proj-prev-lw1_l8 {
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.za_item_background-color_gold {
  background-color: hsl(36, 35%, 66%);
}

.za_item_background-color_purple {
  background-color: #8800e3;
}

.za_item_background-color_darkcyan {
  background-color: darkcyan;
}

.za_item_margin_all_1rem {
  margin: 1rem;
}

.za_item_padding_width_1rem {
  padding-left: 1rem;
  padding-right: 1rem;
}

.s-1-i-avatar {
  max-height: 24rem;
}

.za_font-size_1-25rem {
  font-size: 1.25rem;
}

.za_font-weight_800 {
  font-weight: 800;
}

.za_font-size_1rem {
  font-size: 1rem;
}

.za_item_border_lightblack {
  border-style: double;
  border-radius: 1rem;
  border-width: 0.3rem;
  border-color: rgb(248, 222, 172);
}

.za_item_background-color_1 {
  background-color: rgb(255, 216, 109);
}

.za_item_color_darkslateblue {
  color: darkslateblue !important;
}

.za_font_art_1 {
  font-family: 'Comic Sans MS', sans-serif;
}

.za_font_art_2 {
  font-family: 'Trebuchet MS', sans-serif;
}

.za_font_art_3 {
  font-family: Impact, sans-serif;
}

.za_font_art_4 {
  font-family: Arial, sans-serif;
}

@font-face {
  font-family: 'GoogleFonts-CourgetteRegular';
  src: url('/public/assets/external/fonts.google.com/Courgette-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.za_font_art_5 {
  font-family: 'GoogleFonts-CourgetteRegular';
}

.s-1-divider-l0 {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.s-1-divider-l1p1 {
  margin-right: 1rem;

  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: white;
  border-radius: 1rem;
  border-color: white;

  box-sizing: border-box;
}

.s-1-divider-l1p2 {
  font-size: 2rem;

  color: white;

  box-sizing: border-box;
}

.s-1-divider-l1p3 {
  margin-left: 1rem;

  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: white;
  border-radius: 1rem;
  border-color: white;

  box-sizing: border-box;
}

.s-7-f-common {
  background-color: transparent !important;

  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;

  border-bottom-style: solid !important;
  border-bottom-color: hsl(0, 0%, 66%);
  border-bottom-width: 0.1rem;

  box-shadow: none !important;
}

.s-7-f-common:focus {
  box-shadow: none !important;

  border-bottom-style: solid !important;
  border-bottom-color: hsl(207, 100%, 50%);
  border-bottom-width: 0.1rem;
}

/* bootstrap override 
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after,
.za_item_background-color_none {
  background-color: transparent !important;
}
*/
.form-floating>.bsor_label_bg-transparent~label::after,
.form-floating>.bsor_label_bg-transparent:focus~label::after,
.form-floating>.bsor_label_bg-transparent:not(:placeholder-shown)~label::after,
.form-floating>.bsor_label_bg-transparent~label::after {
  background-color: transparent !important;
}

.za_item_min-height_10rem {
  min-height: 10rem !important;
}

.za_color_transparent_grey {
  color: rgba(128, 128, 128, 0.5) !important;
}

.za_item_background-color_2 {
  background-color: rgba(82, 81, 116, 1) !important;
}

.za_item_background-color_4 {
  background-color: hsla(120, 100%, 50%, 0.125) !important;
}

.za_item_background-color_5 {
  background-color: hsla(93, 100%, 50%, 0.125) !important;
}

.za_item_background-color_6 {
  background-color: hsla(197, 100%, 50%, 0.125) !important;
}

.s-7-e {
  color: #243e36;
  padding: 0;
  margin: 0;
}

.za_item_border_1 {
  border-radius: 1rem;
  border-color: rgba(0, 255, 0, 0.5);
  border-style: double;
  border-width: 0.2rem;
}

.s_7_f_3_ico_max-size {
  max-width: 2rem;
  max-height: 2rem;
}

.s-7-f-2-ico-1 {
  font-size: 1.25rem;
}

.s-7-f-2-a-1 {
  height: 3rem;
  width: 3rem;

  border-radius: 100% !important;
  justify-content: center;
  align-items: center;

  margin: 0.5rem !important;
  padding: 1rem !important;

  box-sizing: border-box;
  display: inline-flex;
}

.s-7-f-4-l-2 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
}

.s-2-divider-l0 {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.s-2-divider-l1p1 {
  margin-right: 1rem;

  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: black;
  border-radius: 1rem;
  border-color: black;

  box-sizing: border-box;
}

.s-2-divider-l1p2 {
  font-size: 2rem;
  color: black;
  box-sizing: border-box;
}

.s-2-divider-l1p3 {
  margin-left: 1rem;

  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: black;
  border-radius: 1rem;
  border-color: black;

  box-sizing: border-box;
}

.za_item_hw_3rem {
  width: 3rem;
  height: 3rem;
}

.za_item_margin-right_05rem {
  margin-right: 0.5rem;
}

.za_item_font-size_xx-large {
  font-size: xx-large;
}

.za_item_rotate_180deg {
  transform: rotate(180deg);
  display: inline-block;
}

.s-5-divider-l0,
.s-4-divider-l0,
.s-3-divider-l0 {
  margin: 1.25rem 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.s-5-divider-l1p1,
.s-4-divider-l1p1,
.s-3-divider-l1p1 {
  margin-right: 1rem;

  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: lightgrey;
  border-radius: 1rem;
  border-color: lightgrey;

  box-sizing: border-box;
}

.s-5-divider-l1p2,
.s-4-divider-l1p2,
.s-3-divider-l1p2 {
  font-size: 2rem;

  color: lightgrey;

  box-sizing: border-box;
}

.s-5-divider-l1p3,
.s-4-divider-l1p3,
.s-3-divider-l1p3 {
  margin-left: 1rem;

  width: 100%;
  max-width: 7rem;
  height: 0.25rem;
  background-color: lightgrey;
  border-radius: 1rem;
  border-color: lightgrey;

  box-sizing: border-box;
}

.s-4-icons {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.5rem;
}

@font-face {
  font-family: 'GoogleFonts-Pangolin-Regular';
  src: url('/public/assets/external/fonts.google.com/Pangolin-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GoogleFonts-Inter-VariableFont_opsz_wght';
  src: url('/public/assets/external/fonts.google.com/Inter-VariableFont_opsz_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.za_font_art_title {
  font-family: 'GoogleFonts-Pangolin-Regular';
}

.za_part_color_whitesmoke {
  color: whitesmoke;
}

.za_item_padding-x_4rem {
  padding-left: 4rem;
  padding-right: 4rem;
}

@font-face {
  font-family: 'GoogleFonts-AnonymousPro-Regular';
  src: url('/public/assets/external/fonts.google.com/AnonymousPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.za_font_art_6 {
  font-family: 'GoogleFonts-AnonymousPro-Regular';
}

@font-face {
  font-family: 'GoogleFonts-PatrickHand-Regular';
  src: url('/public/assets/external/fonts.google.com/PatrickHand-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.za_font_art_7 {
  font-family: 'GoogleFonts-PatrickHand-Regular';
}

#s-7{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
